<template>
  <div
    class="warpper"
    @mouseover="isMouseover = true"
    @mouseout="isMouseover = false"
  >
    <div v-if="banners.length">
      <swiper ref="mySwiper" :options="swiperOptions" class="swiper-banner">
        <template>
          <swiper-slide v-for="(item, index) in banners" :key="index">
            <AppLink :to="`/activitys/activityDetail?id=${item.id}`">
              <div class="swiper-slide-item">
                <img
                  :src="item.banner | formatUrl"
                  class="swiper-slide-item-image"
                />
                <div
                  v-if="item.hideMask !== 1"
                  class="title"
                  data-swiper-parallax="-100"
                >
                  {{ item.title }}
                </div>
                <div
                  v-if="item.hideMask !== 1"
                  class="subTitle"
                  data-swiper-parallax="-300"
                >
                  {{ item.startTime | trimSecond }} -
                  {{ item.endTime | trimSecond }}
                </div>
                <div v-if="item.hideMask !== 1" class="mask"></div>
              </div>
            </AppLink>
          </swiper-slide>
        </template>
      </swiper>
      <img
        :class="['bg', { filter_blur: banners.length === 1 }]"
        :src="
          banners.length === 1
            ? banners[0].banner
            : require('../assets/image.png')
        "
      />
      <div class="swiper-pagination" slot="pagination"></div>
      <div
        :class="[
          'swiper-button-prev',
          { 'swiper-button-disabled': !isMouseover },
        ]"
        slot="button-prev"
      ></div>
      <div
        :class="[
          'swiper-button-next',
          { 'swiper-button-disabled': !isMouseover },
        ]"
        slot="button-next"
      ></div>
    </div>

    <div v-else>
      <div class="separate">
        <img src="../assets/mr.jpg" class="mr" />
      </div>
    </div>
  </div>
</template>

<script>
import AppLink from '@/components/AppLink.vue'
export default {
  name: 'Banner',
  components: {
    AppLink
  },
  props: {
    banners: {
      type: Array,
      // 对象或数组默认值必须从一个工厂函数获取
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      swiperOptions: {
        loop: false,
        initialSlide: 0,
        speed: 400,
        watchOverflow: true,
        centeredSlides: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        lazy: {
          loadPrevNext: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        effect: "coverflow",
        slidesPerView: 'auto',
        loopedSlides: 3,
        coverflowEffect: {
          rotate: 0,
          stretch: 230,
          depth: 300,
          slideShadows: false,
        }
      },
      isMouseover: false
    }
  },
  watch: {
    banners: {
      handler: function (v) {
        if (v.length > 1) {
          this.swiperOptions.loop = true
          this.swiperOptions.loopedSlides = v.length + 2
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
@import url("@/variable.less");
/deep/ .swiper-slide {
  width: 700px !important;
  border-radius: 6px;
}
/deep/ .swiper-pagination {
  position: relative;
  width: 100%;
  margin-top: 10px;
  z-index: 1;
  .swiper-pagination-bullet {
    margin: 0 3px;
  }
  .swiper-pagination-bullet-active {
    width: 14px;
    height: 8px;
    border-radius: 75px;
  }
}
.warpper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 460px;
  margin: 0 auto;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .filter_blur {
    filter: blur(35px);
  }
}

.swiper-banner {
  // width: @base-width;
  width: 1310px;
  margin: 0 auto;
}
.separate {
  width: 100%;
  height: 460px;
  .mr {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.swiper-slide-item-image {
  width: 700px !important;
  height: 394px;
  // box-shadow: 0px 4px 18px 1px rgba(40, 40, 40, 0.21);
  box-shadow: 0px 14px 42px 0px rgba(51, 51, 51, 0.12);
  border-radius: 6px;
  object-fit: contain;
  object-position: 50% 50%;
}
.swiper-slide-item {
  width: 700px !important;
  height: 394px;
  position: relative;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    z-index: 1;
  }
  .title,
  .subTitle {
    width: 100%;
    position: absolute;
    text-align: center;
    color: #fff;
    z-index: 2;
    text-shadow: 1px 1px 2px rgb(71, 68, 68), 0 0 1em rgb(29, 29, 35),
      0 0 0.2em rgb(29, 29, 35);
  }
  .title {
    top: 120px;
    font-size: 25px;
  }
  .subTitle {
    top: 160px;
    font-size: 20px;
  }
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("../assets/prev.png");
  background-size: 35px 35px;
  width: 35px;
  height: 35px;
  left: 2%;
  right: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("../assets/next.png");
  background-size: 35px 35px;
  width: 35px;
  height: 35px;
  right: 2%;
  left: auto;
  transition: opacity 0.3s ease;
}
.swiper-button-disabled {
  opacity: 0 !important;
}
</style>
