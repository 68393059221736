
import { getAction, postAction } from '@/api/manage'

// 获取热门信息
export const fetchHotList = (params = {}) => getAction('/app/index/hotList', params)

// 获取待举办活动列表
export const fetchActivityList = (params = {}) => getAction('/app/activity/list', params)

// 获取往期活动分页列表
export const fetchActivityHistory = (params = {}) => getAction('/app/activity/history', params)

// 获取活动详情
export const fetchActivityDetail = (params = {}) => getAction('/app/activity/queryById', params)

// 获取活动联系人
export const fetchActivityCotacts = (params = {}) => getAction('/app/activity/contact/list', params)

// 获取服务分类列表
export const fetchServerCategory = (params = {}) => getAction('/app/product/category/list', params)

// 获取服务分页列表
export const fetchServerPageList = (params = {}) => getAction('/app/product/pageList', params)

// 获取服务详情
export const fetchServerDetail = (params = {}) => getAction('/app/product/queryById', params)

// 获取服务详情（内部）
export const queryInternalById = (params = {}) => getAction('/app/product/queryInternalById', params)

// 生成内部链接
export const getSharingRecordId = (params = {}) => getAction('/app/product/sharing/getSharingRecordId', params)

// 获取必读分页列表
export const fetchArticleList = (params = {}) => getAction('/app/article/pageList', params)

// 获取必读详情
export const fetchArticleDetail = (params = {}) => getAction('/app/article/queryById', params)

// 获取必读分类列表
export const fetchArticleCategory = (params = {}) => getAction('/app/cms/category/list', params)

// 获取国家列表
export const fetchCountry = (params = {}) => getAction('/app/country/list', params)
export const getCountry = (params = {}) => getAction('/app/country/countryList', params)

export const fetchIpAddress = (params = {}) => getAction('/appUsers/ipAddress', params)

// 搜索全部
export const fetchSearchAll = (params = {}) => getAction('/app/index/search', params)

// 所有活动
export const fetchAllActivityList = (params = {}) => getAction('/app/activity/pageList', params)

// 服务联系人
export const fetchServerContacts = (params = {}) => getAction('/app/product/contact/list', params)

// // 登录回调
// export const loginCallback = (params = {}) => postAction('/wechat/login/callback', params)

// 获取授权登录小程序码
export const fetchQRCode = (params = {}) => getAction('/wxamp/auth/getQRCode', params)

// 获取公司介绍富文本
export const companyMess = (params = {}) => getAction('/app/about/companyMess/list', params)

// 判断是否可以打开链接(电脑端)
export const openLinkByComputer = (params = {}) => postAction('/app/article/articleGetRecord/openLinkByComputer', params)

export const getAppOssSignatureUrl = (params = {}) => postAction('/ossFile/getAppOssSignatureUrl', params)

export const verifyToken = (params = {}) => getAction('/app/token', params)

//判断是不是内部员工
export const checkUser = (params = {}) => getAction('/chat/checkUser', params)
export const queryByIdUser = (params = {}) => getAction('/app/article/queryByIdUser', params)
export const openLink = (params = {}) => postAction('/app/article/articleGetRecord/openLink', params)
