<template>
  <div class="card" :style="{ width }" @click="onClick(content)">
    <div class="card-img">
      <img :src="content.image | formatUrl" class="card-main-img" alt="" />
      <img src="../assets/service_tag.png" v-if="tag" class="card-tag" />
      <span class="card-tag-text" v-if="tag">服务</span>
    </div>
    <div class="card-content">
      <h4 class="card-content-title" :title="content.title">
        {{ content.title }}
      </h4>
      <p
        class="card-content-subname"
        v-if="content.subTitle"
        :title="content.subTitle"
      >
        {{ content.subTitle }}
      </p>
      <p class="card-content-price">{{ content | formatPriceList }}</p>
      <div
        class="card-content-homecountryprice"
        v-if=" HomeCountryformatPriceList(content) && content.homeCurrencySymbol != '￥' ||(content.lowestPriceForUSD !== null || content.priceForUSD !== null)"
      >
        <div v-if="
          content.homeCurrencySymbol != '￥' || (content.lowestPriceForUSD !== null || content.priceForUSD !== null)
        " class="triangle"></div>
        <span v-if="
          content.homeCurrencySymbol != '￥'
        ">{{ content | HomeCountryformatPriceList }}</span>
        <span v-if="(content.lowestPriceForUSD !== null || (content.priceForUSD !== null))" style="margin-left: 5px;">
        <span v-if="
          content.homeCurrencySymbol != '￥' ">|</span> ${{content | formatPriceForUSD}} </span>

      </div>
    </div>
  </div>
</template>

<script>
import { HomeCountryformatPriceList ,formatPriceForUSD } from '@/utils/filter'
export default {
  name: 'ServiceCard',

  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      }
    },
    categoryId: String,
    currentPage: {
      type: [String, Number],
      default: ''
    },
    tag: Boolean,
    width: {
      type: String,
      default: function () {
        return '384px'
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    HomeCountryformatPriceList,
    formatPriceForUSD,
    onClick(content) {
      //   console.log(content, '点击服务卡片')
      this.$router.push({ path: `/products/productDetail?id=${content.id}${this.categoryId ? '&categoryId=' + this.categoryId : ''}${this.currentPage ? '&currentPage=' + this.currentPage : ''}` })
    }
  }
}
</script>

<style scoped lang="less">
.card {
  width: 384px;
  padding: 16px 16px 20px 16px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }
}
.card-tag {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 52px;
  height: 16px;
}
.card-tag-text {
  position: absolute;
  left: 16px;
  top: 2px;
  z-index: 2;
  transform: scale(0.8);
  transform-origin: 0 0;
  display: inline-block;
  font-size: 10px;
  color: #fff;
}
.card-img {
  width: 120px;
  height: 120px;
  margin-right: 16px;
  position: relative;
}
.card-main-img {
  width: 100%;
  height: 100%;
}
.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.card-content-title {
  font-size: 18px;
  margin: 0;
  color: #333;
  width: 214px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}
.card-content-subname {
  font-size: 15px;
  color: #666;
  margin: 0;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}
.card-content-price {
  font-size: 18px;
  margin: 0;
  margin-top: 16px;
  color: #ee0000;
}
.card-content-homecountryprice {
  position: relative;
  width: max-content;
  background: #ebebeb;
  border-radius: 4px 4px 4px 0;
  opacity: 1;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-top: 5px;
  color: #000000;
  padding: 5px 8px;
}
.triangle {
  position: absolute;
  top: -6px;
  left: 0;
    width: 0;
    height: 0;
    border-left: 6px solid #ebebeb;
    border-right: 6px solid transparent;
    border-top: 6px solid transparent;
}
</style>
