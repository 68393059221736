import Vue from 'vue'
import axios from 'axios'
// import store from '@/store'

/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/jeecg-boot'
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */
const apiBaseUrl = window._CONFIG.domianURL || '/jeecg-boot'
console.log(apiBaseUrl)
// console.log("apiBaseUrl= ",apiBaseUrl)
// 创建 axios 实例
const service = axios.create({
  // baseURL: '/jeecg-boot',
  baseURL: apiBaseUrl, // api base_url
  timeout: 20000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get('ACCESS_TOKEN')
    console.log('------异常响应------', token)
    console.log('------异常响应------', error.response.status)
    switch (error.response.status) {
      case 403:
        Vue.notify({ title: '', text: '拒绝访问!', type: 'error' })
        break
      case 500:
        console.log('------error.response------', error.response)
        // update-begin- --- author:liusq ------ date:20200910 ---- for:处理Blob情况----
        if (error.response.request.responseType === 'blob') {
          blobToJson(data)
          break
        }
        // update-end- --- author:liusq ------ date:20200910 ---- for:处理Blob情况----
        // notification.error({ message: '系统提示', description:'Token失效，请重新登录!',duration: 4})
        if (token && data.message.includes('Token失效')) {
          // update-begin- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
          Vue.notify({ title: '', text: 'Token失效', type: 'error' })
        //   Modal.error({
        //     title: '登录已过期',
        //     content: '很抱歉，登录已过期，请重新登录',
        //     okText: '重新登录',
        //     mask: false,
        //     onOk: () => {
        //       store.dispatch('Logout').then(() => {
        //         Vue.ls.remove('ACCESS_TOKEN')
        //         try {
        //           const path = window.document.location.pathname
        //           console.log('location pathname -> ' + path)
        //           if (path !== '/' && path.indexOf('/user/login') === -1) {
        //             window.location.reload()
        //           }
        //         } catch (e) {
        //           window.location.reload()
        //         }
        //       })
        //     }
        //   })
          // update-end- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
        }
        break
      case 404:
        Vue.notify({ title: '', text: '很抱歉，资源未找到!', type: 'error' })
        break
      case 504:
        Vue.notify({ title: '', text: '网络超时', type: 'error' })
        break
      case 401:
        // Vue.notify({ title: '系统提示', text: '未授权，请重新登录', type: 'error' }) 
        Vue.ls.remove('ACCESS_TOKEN')
        break
      default:
        Vue.notify({
          title: '',
          text: data.message,
          type: 'error'
        })
        break
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get('ACCESS_TOKEN')
  if (token) {
    config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  // update-begin-author:taoyan date:2020707 for:多租户
  const currentCountry = Vue.ls.get('CURRENT_COUNTRY')
  let tenantid
  if (currentCountry && typeof currentCountry === 'object') {
    tenantid = currentCountry?.tenantId
  }
  if (!tenantid) {
    tenantid = 0
  }
  config.headers['tenant-id'] = tenantid
  return config
}, (error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  if (response.data.success || response.data.success === undefined) {
    return response.data
  }
  Vue.notify({ title: '', text: response.data.message, type: 'error' })
  throw response.data
}, err)

/**
 * Blob解析
 * @param data
 */
function blobToJson (data) {
  const fileReader = new FileReader()
  const token = Vue.ls.get('ACCESS_TOKEN')
  fileReader.onload = function () {
    try {
      const jsonData = JSON.parse(this.result) // 说明是普通对象数据，后台转换失败
      console.log('jsonData', jsonData)
      if (jsonData.status === 500) {
        console.log('token----------》', token)
        if (token && jsonData.message.includes('Token失效')) {
        //   Modal.error({
        //     title: '登录已过期',
        //     content: '很抱歉，登录已过期，请重新登录',
        //     okText: '重新登录',
        //     mask: false,
        //     onOk: () => {
        //       store.dispatch('Logout').then(() => {
        //         Vue.ls.remove('ACCESS_TOKEN')
        //         window.location.reload()
        //       })
        //     }
        //   })
          Vue.notify({ title: '', text: '登录已过期', type: 'error' })
        }
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log('blob解析fileReader返回err', err)
    }
  }
  fileReader.readAsText(data)
}

export {
  service as axios
}
