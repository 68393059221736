<template>
  <div>
    <div class="content_wrap" @click="handleClick">
      {{ token ? "退出登录" : "登 录" }}
    </div>
    <ProductMask :showBtn="false" title="扫码登录" ref="mask" />
  </div>
</template>

<script>
import Vue from 'vue'
import ProductMask from '@/components/Mask.vue'

export default {
  name: '',
  components: { ProductMask },
  data() {
    return {
    }
  },
  computed: {
    token() { return Vue.ls.get('ACCESS_TOKEN') }
  },
  mounted() { },
  methods: {
    handleClick() {
      if (this.token) {
        Vue.ls.remove('ACCESS_TOKEN')
        location.reload()
      } else {
        this.$refs.mask.goLogin()
      }
    }
  }
}
</script>
<style lang='less' scoped>
.content_wrap {
  border: 1px solid #2223eb;
  color: #2223eb;
  box-sizing: border-box;
  background-color: #d6dff8;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  padding: 0 15px;
  border-radius: 16px;
}
</style>