<template>
  <div class="btn-group">
    <li
      @click="toggleMenu()"
      class="dropdown-toggle"
      v-if="selectedOption.countryAbbreviation !== undefined"
    >
      <img :src="selectedOption.logo | formatUrl" class="icon" alt="" />
      {{ selectedOption.countryAbbreviation }}
      <span class="caret"></span>
    </li>

    <li
      @click="toggleMenu()"
      class="dropdown-toggle dropdown-toggle-placeholder"
      v-if="selectedOption.countryAbbreviation === undefined"
    >
      {{ placeholderText }}
      <span class="caret"></span>
    </li>

    <ul class="dropdown-menu" v-if="showMenu&&countrys.length>0">
      <li v-for="(option, idx) in countrys" :key="idx">
        <a href="javascript:void(0)" @click="updateOption(option)">
          <img :src="option.logo | formatUrl" class="icon" alt="" />
          {{ option.countryAbbreviation }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import Vue from 'vue'
export default {
  data() {
    return {
      selectedOption: {
        countryAbbreviation: ''
      },
      showMenu: false,
      placeholderText: '请选择',
      countrys: [],
    }
  },
  props: {
    options: {
      type: [Array, Object]
    },
    selected: {
      type: Object,
      default: () => ({})
    },
    placeholder: [String],
    closeOnOutsideClick: {
      type: [Boolean],
      default: true
    }
  },
  watch: {
    selected: {
      deep: true,
      immediate: true,
      handler: function (val = {}) {
        this.selectedOption = val
      }
    },
    options: {
      immediate: true,
      handler: function (val = {}) {
        let obj = {
        	home: 'home',
        	products: 'product',
        	articles: 'article',
        	activitys: 'activity',
        }
        if (this.options.length > 0 && obj[this.$route.name]) {
          this.countrys = this.options.find(item => item.type === obj[this.$route.name]).countryList
          Vue.ls.set('USE_COUNTRYS', this.countrys)
        }else {

          if(Vue.ls.get('USE_COUNTRYS')){
            this.countrys = Vue.ls.get('USE_COUNTRYS')
          }
        }
      }
    },
    '$route'(to, from) {
      let obj = {
      	home: 'home',
      	products: 'product',
      	articles: 'article',
      	activitys: 'activity',
      }
      if (this.options.length > 0 && obj[to.path.substring(1)]) {
        this.countrys = this.options.find(item => item.type === obj[to.path.substring(1)]).countryList
        Vue.ls.set('USE_COUNTRYS', this.countrys)
      }else {

        if(Vue.ls.get('USE_COUNTRYS')){
          this.countrys = Vue.ls.get('USE_COUNTRYS')
        }
      }
    }
  },
  mounted() {
    if (this.placeholder) {
      this.placeholderText = this.placeholder
    }
    if (this.closeOnOutsideClick) {
      document.addEventListener('click', this.clickHandler)
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    updateOption(option) {
      this.selectedOption = option
      this.showMenu = false
      this.$emit('updateOption', this.selectedOption)
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    clickHandler(event) {
      const { target } = event
      const { $el } = this
      if (!$el.contains(target)) {
        this.showMenu = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.btn-group {
  min-width: 100px;
  height: 40px;
  position: relative;
  margin: 10px 1px;
  display: inline-block;
  vertical-align: middle;
}
.btn-group a:hover {
  text-decoration: none;
}
.dropdown-toggle {
  color: #636b6f;
  min-width: 70px;
  padding: 10px 20px 10px 10px;
  text-transform: none;
  font-weight: 300;
  margin-bottom: 7px;
  border: 0;
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dropdown-toggle:hover {
  background: #e1e1e1;
  cursor: pointer;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 100px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.dropdown-menu > li > a {
  padding: 10px 30px;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: #333333;
  white-space: nowrap;
  text-decoration: none;
  img {
    vertical-align: middle;
  }
}
.dropdown-menu > li > a:hover {
  background: #efefef;
  color: #333333;
}
.dropdown-menu > li {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0;
}
.caret {
  width: 0;
  position: absolute;
  top: 19px;
  height: 0;
  margin-left: -24px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  right: 10px;
}
li {
  list-style: none;
}
.icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 4px;
  object-fit: contain;
  border-radius: 50%;
  border: 1px #eee solid;
  background: #fff;
}
</style>
