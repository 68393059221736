import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    countrys: [], // 国家列表
    currentCountry: {} ,// 当前国家
    countryList: [],
  },
  getters: {
  },
  mutations: {
    setCurrentCountry (state, params) {
      Vue.ls.set('CURRENT_COUNTRY', params)
      state.currentCountry = params
    },
    setCountrys (state, params) {
      state.countrys = params
    },
    setCountryList(state, params) {
      state.countryList = params
    },
  },
  actions: {
  },
  modules: {
  }
})
