
import Vue from 'vue'
import { DIS_PERSONALLY_NUM, FILE_SPLIT } from '@/utils/contants'
import { formatAmount } from '@/utils/index.js'

export const formatUrl = (u) => {
  if ((/^(http)s?:\/\//.test(u))) return u
  return `${window._CONFIG.staticDomainURL}/${u}`
}
//产品列表价格过滤器
export const formatPriceList = (item, isAll = false) => {
  const formatIntegerPrice = (item) => {
    if (!item) return item
    return formatAmount(item)
    // return item.toLocaleString()
  }
  if (!Vue.ls.get('ACCESS_TOKEN')) {
    return ''
  }
  if (!Vue.ls.get('isInner')) {
    return ''
  }
  if (item.price && item.price >= DIS_PERSONALLY_NUM || item.lowestPrice >= DIS_PERSONALLY_NUM) {
    return '价格面议'
  }
  if (item.type === '01') {
    if (!item.isMuchSpec) {
      // 价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.priceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      return `￥${formatIntegerPrice(item.price + item.postage)}`
    } else if (item.isMuchSpec && !isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      if (item.lowestPrice === item.highestPrice) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}`
      }
      return `￥${formatIntegerPrice(item.lowestPrice + item.postage)} 起`
    } else if (item.isMuchSpec && isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      if (item.lowestPrice === item.highestPrice) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}`
      }
      if (item.highestPriceForHomeCountry && item.highestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)} 起`
      }
      return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}~${formatIntegerPrice(item.highestPrice + item.postage)}`
    }
  } else {
    return '价格面议'
  }
}
//产品详情价格过滤器
export const formatPrice = (item, isAll = false) => {
  const formatIntegerPrice = (item) => {
    if (!item) return item
    return item.toLocaleString()
  }
  if (item.price && item.price >= DIS_PERSONALLY_NUM || item.lowestPrice >= DIS_PERSONALLY_NUM) {
    return '价格面议'
  }
  if (item.type === '01') {
    if (!item.isMuchSpec) {
        //产品详情内，单规格重做，从规格项数据里拿去数据
      if(item.skuList&&item.skuList.length>0){
        if (item.skuList[0].unitPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
          return '价格面议'
        }
        return `￥${formatIntegerPrice(item.skuList[0].unitPrice + item.postage)}`
      }else{
        return '价格：请联系我们'
      }
      
      // 价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      // if (item.priceForHomeCountry >= DIS_PERSONALLY_NUM) {
      //   return '价格面议'
      // }
      // return `￥${formatIntegerPrice(item.price + item.postage)}`
    } else if (item.isMuchSpec && !isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      if (item.lowestPrice === item.highestPrice) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}`
      }
      return `￥${formatIntegerPrice(item.lowestPrice + item.postage)} 起`
    } else if (item.isMuchSpec && isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      if (item.lowestPrice === item.highestPrice) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}`
      }
      if (item.highestPriceForHomeCountry && item.highestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)} 起`
      }
      return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}~${formatIntegerPrice(item.highestPrice + item.postage)}`
    }
  } else {
    return '价格面议'
  }
}
export const HomeCountryformatPriceList = (item, isAll = false) => {
  const formatIntegerPrice = (item) => {
    if (!item) return item
    return item.toLocaleString()
  }
  if (!Vue.ls.get('ACCESS_TOKEN')||!Vue.ls.get('isInner')) {
    return ''
  }
  if (item.price && item.price >= DIS_PERSONALLY_NUM || item.lowestPrice >= DIS_PERSONALLY_NUM) {
    return ''
  }
  if (item.type === '01') {
    if (!item.isMuchSpec) {
      // 价格大于九万亿，认为是面议，需要将之前0改为100亿，以前0认为就是面议
      if (item.priceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.priceForHomeCountry + item.postageForHomeCountry)}`
    } else if (item.isMuchSpec && !isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      if (item.lowestPriceForHomeCountry === item.highestPriceForHomeCountry) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}`
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)} 起`
    } else if (item.isMuchSpec && isAll) {
      // 最大价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      if (item.lowestPriceForHomeCountry === item.highestPriceForHomeCountry) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}`
      }
      if (item.highestPriceForHomeCountry && item.highestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)} 起`
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}~${formatIntegerPrice(item.highestPriceForHomeCountry + item.postageForHomeCountry)}`
    }
  } else {
    return ''
  }
}
export const formatPriceForUSD = (item, isAll = false,isPermission = false) => {
   const formatIntegerPrice = (item) => {
     if (!item) return item
     return item.toLocaleString()
   }

  if (item.type === '01') {
    if (!item.isMuchSpec) {
      return `${formatIntegerPrice(item.priceForUSD + item.postageForUSD)}`
    } else if(item.isMuchSpec && !isAll) {
      
      if (item.lowestPriceForUSD === item.highestPriceForUSD) {
        return `${formatIntegerPrice(item.lowestPriceForUSD + item.postageForUSD)}`
      }
      
      return `${formatIntegerPrice(item.lowestPriceForUSD + item.postageForUSD)}起`
    }else if(item.isMuchSpec && isAll) {
      
      if (item.lowestPriceForUSD === item.highestPriceForUSD) {
        return `${formatIntegerPrice(item.lowestPriceForUSD + item.postageForUSD)}`
      }
      if (!item.highestPriceForUSD) {
        return `${formatIntegerPrice(item.lowestPriceForUSD + item.postageForUSD)}起`
      }
      return `${formatIntegerPrice(item.lowestPriceForUSD + item.postageForUSD)}~${formatIntegerPrice(item.highestPriceForUSD + item.postageForUSD)}`
    }
  } else {
    return ''
  }
}
export const HomeCountryformatPrice = (item, isAll = false) => {
  const formatIntegerPrice = (item) => {
    if (!item) return item
    return item.toLocaleString()
  }
  if (item.price && item.price >= DIS_PERSONALLY_NUM || item.lowestPrice >= DIS_PERSONALLY_NUM) {
    return ''
  }
  if (item.type === '01') {
    if (!item.isMuchSpec) {
      // 价格大于九万亿，认为是面议，需要将之前0改为100亿，以前0认为就是面议
      if (item.priceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.priceForHomeCountry + item.postageForHomeCountry)}`
    } else if (item.isMuchSpec && !isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      if (item.lowestPriceForHomeCountry === item.highestPriceForHomeCountry) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}`
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)} 起`
    } else if (item.isMuchSpec && isAll) {
      // 最大价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      if (item.lowestPriceForHomeCountry === item.highestPriceForHomeCountry) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}`
      }
      if (item.highestPriceForHomeCountry && item.highestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)} 起`
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}~${formatIntegerPrice(item.highestPriceForHomeCountry + item.postageForHomeCountry)}`
    }
  } else {
    return ''
  }
}

export const trimSecond = (date) => {
  date = date || ''
  const _idx = date.lastIndexOf(':')
  return date.slice(0, _idx)
}

export const getFileName = path => {
  if (!path) return ''
  if (path.lastIndexOf('\\') >= 0) {
    let reg = new RegExp('\\\\', 'g')
    path = path.replace(reg, '/')
  }
  let name = ''
  try {
    name = decodeURIComponent(path.substring(path.lastIndexOf('/') + 1))
  } catch (e) {
    name = path.substring(path.lastIndexOf('/') + 1)
  }
  let viewName = name.split(FILE_SPLIT)
  let suffix = ''

  if (viewName.length === 1) return viewName[0]
  if (viewName.length === 2) {
    // 唯一标识在前 情况下
    if (name.indexOf('vc-upload-') === 0) {
      suffix = viewName[viewName.length - 1]
    } else {
      suffix = `${viewName[0]}.${viewName[1].split('.')[1]}`
    }
  } else {
    let i = viewName.findIndex(item => !/^(vc-upload-).*/.test(item))
    // 如果截取出来的字段 不带唯一标识，并且为最后一个字段，则直接返回
    if (i === viewName.length - 1) {
      suffix = viewName[i]
    } else {
      suffix = `${viewName[i]}.${viewName[viewName.length - 1].split('.')[1]}`
    }
  }
  return suffix
}

/**
 *
 * @desc 文本复制
 * @params text  需要复制的文本
 */

export function copyText(text) {
  // 堆代码 duidaima.com
  // 方法1：尝试使用clipboardData对象将文本复制到剪贴板中
  const clipboardData = window.clipboardData;
  if (clipboardData) { // 如果clipboardData对象存在，说明浏览器支持此方法
    clipboardData.clearData(); // 清除剪贴板中的旧数据
    clipboardData.setData('Text', text); // 将text数据复制到剪贴板中
    return true; // 返回复制成功
  }
  // 方法2：使用document.execCommand方法将文本复制到剪贴板中
  // 注意，该方法已经被弃用，但仍然有些浏览器支持，使用时要注意兼容性
  else if (document.execCommand) {
    // 创建一个textarea元素，并将需要复制的文本赋值给它
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', ''); // 设置为只读模式，防止用户误操作
    el.style.position = 'absolute';
    el.style.left = '-9999px'; // 将元素的left设置为-9999px，相当于将其放到屏幕外面
    document.body.appendChild(el); // 将该元素添加到body中
    el.select(); // 选中该元素中的文本
    // 将该元素中的文本复制到剪贴板中
    document.execCommand('copy');
    // 删除该元素
    document.body.removeChild(el);
    return true; // 返回复制成功
  }
  return false; // 复制失败
}

Vue.filter('formatUrl', formatUrl)
Vue.filter('formatPrice', formatPrice)
Vue.filter('formatPriceList', formatPriceList)

Vue.filter('trimSecond', trimSecond)
Vue.filter('HomeCountryformatPrice', HomeCountryformatPrice)
Vue.filter('HomeCountryformatPriceList', HomeCountryformatPriceList)
Vue.filter('formatPriceForUSD', formatPriceForUSD)
Vue.filter('getFileName', getFileName)
Vue.filter('copyText', copyText)
