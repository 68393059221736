import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../views/ProductsView.vue')
  },
  {
    path: '/products/productDetail',
    name: 'productDetail',
    component: () => import(/* webpackChunkName: "productDetail" */ '../views/ProductsDetailView.vue')
  },
  {
    path: '/products/productDetailInner',
    name: 'productDetailInner',
    component: () => import(/* webpackChunkName: "productDetailInner" */ '../views/ProductsDetailViewInner.vue')
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import(/* webpackChunkName: "articles" */ '../views/ArticlesView.vue')
  },
  {
    path: '/articles/articleDetail',
    name: 'articleDetail',
    component: () => import(/* webpackChunkName: "articleDetail" */ '../views/ArticleDetailView.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "activitys" */ '../views/AboutUsView.vue')
  },
  {
    path: '/activitys',
    name: 'activitys',
    component: () => import(/* webpackChunkName: "activitys" */ '../views/ActivitysView.vue')
  },
  {
    path: '/activitys/activityDetail',
    name: 'activityDetail',
    component: () => import(/* webpackChunkName: "activityDetail" */ '../views/ActivityDetailView.vue')
  },
  {
    path: '/cases',
    name: 'cases',
    component: () => import(/* webpackChunkName: "cases" */ '../views/CasesView.vue')
  },
  {
    path: '/home/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/SearchView.vue')
  },
  {
    path: '/auth/wechat/callback',
    redirect: '/products/authWechat'
  },
  {
    path: '/products/authWechat',
    name: 'authWechat',
    component: () => import(/* webpackChunkName: "authWechat" */ '../views/AuthWechatView.vue')
  },
  {
    path: '/pdf',
    name: 'pdfPage',
    component: () => import(/* webpackChunkName: "authWechat" */ '../views/Pdf.vue')
  },
  {
    path: '/pdf/pdfjs',
    name: 'pdfjs',
    component: () => import(/* webpackChunkName: "authWechat" */ '../views/Pdfjs.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
router.beforeEach((to, from, next) => {
  let obj = {
  	home: 'home',
  	products: 'product',
  	articles: 'article',
  	activitys: 'activity',
  }
  if (store.state.countryList.length > 0 && obj[to.name]) {
    let countrys = store.state.countryList.find(item => item.type === obj[to.name]).countryList
    let index = countrys.findIndex(
      (item) => item.tenantId === store.state.currentCountry.tenantId
    );
    if(index == -1){
      store.commit('setCurrentCountry', countrys[0])
    }
  }
  next();
})

export default router
