<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="container">
          <slot></slot>
        </div>
        <img class="img" src="../assets/Group952.jpg" />
        <img
          class="modal-close"
          src="../assets/close.svg"
          @click="$emit('close')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal-container {
  position: relative;
  display: flex;
  width: 802px;
  height: 639px;
  background: #fff;
  border-radius: 4px;
  .container {
    width: calc(100% - 325px);
    padding: 35px 30px;
    overflow: hidden;
  }
  .img {
    width: 325px;
    height: 100%;
  }
}
.modal-close {
  position: absolute;
  right: 17px;
  top: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
</style>
