<template>
  <div class="home">
    <Banner :banners="banners" />
    <div class="services-container">
      <div class="services">
        <Title title="我们的服务" />
        <div class="services-wrapper">
          <div
            class="services-item"
            v-for="item in services"
            :key="item.id"
            @click="goToProductsView(item)"
          >
            <img
              v-if="item.icon || item.iconMobile"
              :src="`${item.icon || item.iconMobile}` | formatUrl"
              class="service-item-img"
            />
            <img
              v-else
              src="../assets/placeholder.png"
              class="service-item-img"
            />
            <div class="service-item-text" :title="item.name">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hot-container">
      <div class="hot">
        <Title title="热门信息">
          <div class="hot-message-btn" slot="after">
            <div
              :class="`hot-message-btn-item ${hotActive == 0 ? 'active' : ''}`"
              @click="slideArticleTo"
            >
              热门文章
            </div>
            <div
              :class="`hot-message-btn-item ${hotActive == 1 ? 'active' : ''}`"
              @click="slideServiceTo"
            >
              热门服务
            </div>
          </div>
        </Title>
        <swiper
          ref="myHotSwiper"
          :options="swiperOptions"
          class="hot-swiper-banner"
        >
          <swiper-slide>
            <div class="hot-wrapper">
              <div class="hot-message" v-for="item in articles" :key="item.id">
                <AriticleCard :content="item" :tag="true" />
              </div>
            </div>
            <LearnMoreBtn url="/articles" />
          </swiper-slide>
          <swiper-slide>
            <div class="hot-wrapper">
              <div class="hot-message" v-for="item in products" :key="item.id">
                <ServiceCard :content="item" :tag="true" />
              </div>
            </div>
            <LearnMoreBtn url="/products" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- <div class="case-container">
        <div class="case">
          <Title title="为超过500家企业提供服务" />
          <div class="case-wrapper">
            <div class="case-item" v-for="(item, index) in cases" :key="index" @click="onCaseClick(item)">
              <img class="case-item-img" :src="item.thumbnail">
            </div>
          </div>
          <LearnMoreBtn />
        </div>
      </div> -->
    <ArticleldReport v-if="showModal" @close="showModal = false">
      <div class="report">
        <div v-if="!noPermission">
          <div class="report-title">{{ reportTitle }}</div>
          <div class="report-num">{{ ossFiles.length }}份资料</div>
          <div
            class="report-list"
            v-for="item in ossFiles"
            :key="item.id"
            @click="viewFile(item)"
          >
            <div class="report-name">{{ item.displayName | getFileName }}</div>
            <img style="width: 7px" src="./../assets/Rectangle2366.svg" />
          </div>
        </div>
        <div v-else class="no-permission">
          <img style="width: 70px" src="./../assets/Group953.svg" />
          <p>无权限查看文章资料</p>
        </div>
      </div>
    </ArticleldReport>
    <ProductMask :showBtn="false" title="扫码登录查看资料" ref="mask" />
  </div>
</template>

<script>
// @ is an alias to /src
import Title from '@/components/Title.vue'
import Banner from '@/components/Banner.vue'
import AriticleCard from '@/components/AriticleCard.vue'
import ServiceCard from '@/components/ServiceCard.vue'
import LearnMoreBtn from '@/components/LearnMoreBtn.vue'
import ProductMask from '@/components/Mask.vue'
import ArticleldReport from '@/components/ArticleldReport'
import AES from '@/utils/AES'
import { mapState } from 'vuex'
import * as api from '@/api'

const HOT_ITEM_TYPE = {
  ARTICLE: '1',
  SERVER: '2',
  ACTIVITY: '3'
}

export default {
  name: 'HomeView',
  components: {
    Title,
    Banner,
    AriticleCard,
    ServiceCard,
    LearnMoreBtn,
    ProductMask,
    ArticleldReport
  },
  data() {
    const that = this
    return {
      hotActive: 0,
      swiperOptions: {
        initialSlide: 0,
        speed: 800,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false
        },
        on: {
          slideChangeTransitionStart: function () {
            that.hotActive = this.activeIndex
          }
        }
      },
      banners: [],
      services: [],
      articles: [],
      products: [],
      showModal: false,
      ossFiles: [],
      reportTitle: '--',
      noPermission: false
    }
  },
  created() {
    this.openLinkByComputer() // 查看文章资料
    this.fetchActivityList()
    this.fetchServerCategory()
    this.fetchHotList()
  },
  watch: {
    currentCountry: {
      deep: true,
      immediate: true,
      handler: function (val = {}) {
        this.openLinkByComputer() // 查看文章资料
        this.fetchActivityList()
        this.fetchServerCategory()
        this.fetchHotList()
      }
    },
  },
  mounted() {
    // 鼠标覆盖停止自动切换
    this.swiper.el.onmouseover = function () {
      this.swiper.autoplay.stop()
    }

    // 鼠标离开开始自动切换
    this.swiper.el.onmouseout = function () {
      this.swiper.autoplay.start()
    }
  },
  methods: {
    openLinkByComputer() {
      if (this.articleId) {
        api.openLinkByComputer({ articleId: this.articleId }).then(res => {
          if (res.success && res.result.articleFileMgt) {
            this.reportTitle = res.result.title || '--'
            this.ossFiles = res.result.articleFileMgt.ossFiles || []
            if (this.ossFiles.length > 1) {
              this.showModal = true
            } else {
              this.viewFile(this.ossFiles[0], 'once')
            }
          } else {
            this.noPermission = true
            this.showModal = true
          }
        }).catch(error => {
          if (error.response.status === 401) {
            this.$refs.mask.goLogin()
          }
        })
      }
    },
    async viewFile(item, once) {
      const { result } = await api.getAppOssSignatureUrl([{ id: item.id }])
      let obj = {
        url: result[0],
        isReadOnly: true
      }
      window.sessionStorage.setItem('ossFiles', AES.encrypt(JSON.stringify(obj)))
      if (once) {
        this.$router.replace({ path: '/pdf/pdfjs' })
      } else {
        window.open(`${window.location.origin}/#/pdf/pdfjs`)
      }
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    // onCaseClick (item) {
    //   console.log('案例点击', item)
    // },
    slideArticleTo() {
      if (this.hotActive === 0) {
        return
      }
      if (this.hotActive === 1) {
        this.hotActive = 0
        this.swiper.slideTo(0, 800, false)
      }
    },
    slideServiceTo() {
      if (this.hotActive === 1) {
        return
      }
      if (this.hotActive === 0) {
        this.hotActive = 1
        this.swiper.slideTo(1, 800, false)
      }
    },
    // 请求待办活动列表
    fetchActivityList() {
      this.$loading(true)
      api.fetchActivityList({ queryScenarios: 'home' }).then(res => {
        if (res.success) {
          console.log(res, '待办活动')
          const { result } = res
          this.banners = result || []
          // this.banners = [{
          //   "id": "1898980317564035073",
          //   "title": "hgx测试活动222",
          //   "subTitle": null,
          //   "startTime": "2025-03-10 14:12:14",
          //   "endTime": "2025-03-20 14:12:14",
          //   "thumbnail": "https://shm-public-test.oss-cn-huhehaote.aliyuncs.com/shm/activity/hgx%E6%B5%8B%E8%AF%95%E6%B4%BB%E5%8A%A8222%25SHMAP%25-3774.png",
          //   "banner": "https://shm-public-test.oss-cn-huhehaote.aliyuncs.com/shm/activity/hgx%E6%B5%8B%E8%AF%95%E6%B4%BB%E5%8A%A8222%25SHMAP%25-854.png",
          //   "type": 1,
          //   "address": "1232",
          //   "isLink": false,
          //   "link": null,
          //   "hideMask": 1,
          //   "pinned": 0
          // }]
        } else {
          this.$notify({ title: '', text: res.message, type: 'warn' })
        }
      }).finally(() => {
        this.$loading(false)
      })
    },
    // 请求服务分类
    fetchServerCategory() {
      this.$loading(true)
      api.fetchServerCategory().then(res => {
        if (res.success) {
          console.log(res, '服务分类')
          const { result } = res
          this.services = result || []
        } else {
          this.$notify({ title: '', text: res.message, type: 'warn' })
        }
      }).finally(() => {
        this.$loading(false)
      })
    },
    // 获取热门信息
    fetchHotList() {
      this.$loading(true)
      api.fetchHotList().then(res => {
        if (res.success) {
          console.log(res, '热门信息')
          const { result = [] } = res
          const articles = []
          const products = []
          result.forEach(element => {
            if (element.hotType === HOT_ITEM_TYPE.ARTICLE) {
              articles.push(element)
            } else if (element.hotType === HOT_ITEM_TYPE.SERVER) {
              products.push(element)
            }
          })
          this.products = products
          this.articles = articles
        } else {
          this.$notify({ title: '', text: res.message, type: 'warn' })
        }
      }).finally(() => {
        this.$loading(false)
      })
    },
    goToProductsView(item) {
      this.$router.push({ path: `/products?id=${item.id}` })
    }
  },
  computed: {
    ...mapState(["currentCountry"]),
    swiper() {
      return this.$refs.myHotSwiper.swiper
    },
    articleId() { return this.$route.query.articleId }
  }
}
</script>

<style scoped lang="less">
/deep/ .modal-container {
  margin-top: -20vh;
}
/deep/ .iframe-style .modal-container {
  margin-top: 0;
  height: 97%;
  .modal-body {
    height: 93%;
  }
  .model-container {
    height: 97%;
  }
}
@import url("@/variable.less");
.services-container {
  background-color: #fff;
}
.hot-container {
  background-color: #f8f8f8;
}
.case-container {
  background-color: #f8f8f8;
  min-height: 530px;
  background-image: url("../assets/case_bg.jpg");
  background-size: cover;
  background-position: right;
}
.services,
.hot,
.case {
  width: @base-width;
  padding: 40px 0;
  padding-bottom: 0;
  margin: auto;
  box-sizing: border-box;
}
.hot,
.case {
  padding: 40px 0 0;
}
.services-wrapper,
.hot-wrapper,
.case-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 56px;
}
.hot-wrapper,
.case-wrapper {
  margin-top: 24px;
}
.case-wrapper {
  background-color: #fff;
  border: 1px #d8d8d8 solid;
  margin-bottom: 24px;
}
.services-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px 50px 0;
  width: calc((100% - 25px) / 6);
  min-width: calc((100% - 25px) / 6);
  max-width: calc((100% - 25px) / 6);
  &:nth-child(6n) {
    margin-right: 0;
  }
}
.case-item {
  flex: 1;
  margin: 0;
  width: calc(100% / 5);
  min-width: calc(100% / 5);
  max-width: calc(100% / 5);
  background: #fff;
  height: 160px;
  position: relative;
  cursor: pointer;
}
.case-item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 160px;
  max-height: 90px;
  transform: scale(1);
  transition: all ease 0.5s;
}

.case-item:hover {
  .case-item-img {
    transform: scale(1.1);
  }
}

.hot-message {
  flex: 1;
  margin: 0 24px 24px 0;
  width: calc((100% - 48px) / 3);
  min-width: calc((100% - 48px) / 3);
  max-width: calc((100% - 48px) / 3);
  &:nth-child(3n) {
    margin-right: 0;
  }
}
.hot-message-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  font-size: 15px;
  & > .hot-message-btn-item {
    padding: 8px 20px;
    cursor: pointer;
  }
  & > .hot-message-btn-item:first-child {
    color: #333;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    border-right: none;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
  & > .hot-message-btn-item:last-child {
    color: #333;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    border-left: none;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }
  & > .hot-message-btn-item.active {
    background-color: #2223eb;
    border: 1px solid #2223eb;
    color: #fff;
  }
}
.service-item-img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
}
.service-item-text {
  color: #333;
  font-size: 18px;
  display: inline-block;
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 30px;
}
.model-container {
  padding: 40px;
  padding-top: 0;
}
.file_wrap {
  line-height: 26px;
  .file {
    cursor: pointer;
    color: #3970f6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .img {
    width: 20px;
    vertical-align: middle;
  }
}
.report {
  height: 100%;
  overflow: auto;
  .report-title {
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
  }
  .report-num {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    margin: 8px 0 20px 0;
  }
  .report-list {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    padding: 20px;
    background: #f2f8ff;
    border-radius: 13px;
    margin-bottom: 13px;
    .report-name {
      width: calc(100% - 10px);
      font-weight: 600;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
    &:hover {
      .report-name {
        color: #2223eb;
      }
    }
  }
  .no-permission {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.7);
      margin-top: 25px;
    }
  }
}
</style>
